<template>
  <section class="py-4 px-5">
    <b-form-row v-if="restrictUsersToMultipleCity">
      <b-col>
        <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_USER_DETAILS_ERROR }}</b-alert>
      </b-col>
    </b-form-row>
    <h3 class="page-header">Users</h3>
    <b-row align-h="between" class="my-4">
      <b-col lg="7" md="8" sm="9" cols="12">
        <div class="floating-input-field">
          <b-form-input
            id="form-input-search-users"
            class="mb-2 mb-sm-1"
            placeholder=" "
            v-model.trim="searchText"
            type="text"
            @input="onSearchUsers()"
          ></b-form-input>
          <label for="form-input-search-users">Search by name, email & telephone <BIconSearch class="input-search-icon" /></label>
        </div>
      </b-col>
      <b-col lg="5" md="4" sm="3" cols="12" class="mb-2 mb-sm-1 text-right">
        <b-dropdown class="action-dropdown" no-caret variant="outline-secondary" :disabled="!usersSelected.length || restrictUsersToMultipleCity" right>
          <template #button-content>
            Group actions
            <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
          </template>
          <template v-for="(action, index) in userGroupActionsOptions">
            <b-dropdown-item v-if="action.value !== 3" href="javascript:void(0)" :key="index" @click="userAction(action, true, '')">{{
              action.text
            }}</b-dropdown-item>
          </template>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- Users table element -->
    <b-table
      :items="getUserItems"
      :fields="fields"
      :current-page="currentPage"
      :per-page="0"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      show-empty
      @row-dblclicked="onUserRowDblclicked"
      @context-changed="setTableCache($event, 'users')"
      hover
      class="table-transition"
      id="users-table"
      primary-key="id"
      :tbody-transition-props="transProps"
      :busy="getUsersLoading"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(checkbox)="row">
        <b-form-checkbox :disabled="restrictUsersToMultipleCity" :checked="row.item.checked" @change="selectUser($event, row.item)"> </b-form-checkbox>
      </template>
      <template #cell(fullName)="row">
        <router-link
          class="theme-font-bold"
          :class="{ 'pe-none': restrictUsersToMultipleCity }"
          :to="'/users/userdetails/' + row.item.userObjectID"
          v-if="row.item.fullName"
        >
          {{ row.item.fullName }}
        </router-link>
      </template>
      <template #cell(actions)="row">
        <b-dropdown class="action-dropdown" no-caret variant="outline-secondary" :disabled="restrictUsersToMultipleCity" right>
          <template #button-content>
            Actions
            <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
          </template>
          <b-dropdown-item
            href="javascript:void(0)"
            @click="userAction(action, false, row.item)"
            v-for="(action, index) in userGroupActionsOptions"
            :disabled="(row.item.isActive && action.value === 1) || (!row.item.isActive && action.value === 0)"
            :key="index"
            >{{ action.text }}</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>
    <!-- Table options-->
    <b-form-row>
      <b-col sm="6" md="6" lg="3" cols="12" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="5"
          label-cols-md="6"
          label-cols-lg="4"
          label-cols-xl="3"
          label-cols="3"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm" @change="onPageSizeChange($event)"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="d-none d-lg-block"></b-col>
      <b-col sm="6" md="6" lg="3" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
          @change="onPageChange"
        ></b-pagination>
      </b-col>
    </b-form-row>
    <ConfirmationModal
      :showModal="activateUserConfirmModal"
      :title="`CONFIRM`"
      :message="activateDeactivateMessage"
      @onConfirm="activateOrDeactivateUser"
      @closeConfirmModal="activateUserConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="resetPasswordConfirmModal"
      :title="`CONFIRM`"
      :message="resetPasswordMessage"
      @onConfirm="resetPassword"
      @closeConfirmModal="resetPasswordConfirmModal = false"
    />
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { UserGroupActionsOptions, DISPLAY_MESSAGES } from '@/utilities/constants'
import { BIconSearch, BIconChevronDown } from 'bootstrap-vue'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import debounce from 'lodash/debounce'
import { useTableOptionsCache } from '@/composables/useTableOptionsCache'

export default {
  name: 'Users',
  setup() {
    const { filter, perPage, currentPage, sortBy, sortDesc, getTableCache, setTableCache } = useTableOptionsCache('languages')
    const { isLoading: getUsersLoading, response: getUsersResponse, error: getUsersError, callApi: getUsersApi } = useAxios()
    const {
      isLoading: activateDeactivateLoading,
      response: activateDeactivateResponse,
      error: activateDeactivateError,
      callApi: activateDeactivateApi,
    } = useAxios()
    const { isLoading: resetPasswordLoading, response: resetPasswordResponse, error: resetPasswordError, callApi: resetPasswordApi } = useAxios()

    return {
      getUsersLoading,
      getUsersResponse,
      getUsersError,
      getUsersApi,
      filter,
      perPage,
      currentPage,
      sortBy,
      sortDesc,
      getTableCache,
      setTableCache,
      activateDeactivateLoading,
      activateDeactivateResponse,
      activateDeactivateError,
      activateDeactivateApi,
      resetPasswordLoading,
      resetPasswordResponse,
      resetPasswordError,
      resetPasswordApi,
    }
  },
  computed: {
    activateDeactivateMessage() {
      if (!this.selectedAction) {
        if (this.selectedGroup) return `DEACTIVATE_USERS`
        else return `DEACTIVATE_USER`
      } else {
        if (this.selectedGroup) return `ACTIVATE_USERS`
        else return `ACTIVATE_USER`
      }
    },
    resetPasswordMessage() {
      if (this.selectedGroup) return `RESET_PASSWORD_USERS`
      else return `RESET_PASSWORD_USER`
    },
    restrictUsersToMultipleCity() {
      return this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)
    },
    getUserItems() {
      return this.getUsersResponse?.usersInfo || []
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  components: {
    BIconSearch,
    BIconChevronDown,
    ConfirmationModal: () => import('../common/ConfirmationModal.vue'),
  },
  data() {
    return {
      userGroupActionsOptions: UserGroupActionsOptions,
      transProps: {
        // Transition name
        name: 'flip-list',
      },
      usersSelected: [],
      selectedGroup: null,
      selectedAction: null,
      activateUserConfirmModal: false,
      resetPasswordConfirmModal: false,
      fields: [
        { key: 'checkbox', label: '' },
        {
          key: 'fullName',
          label: 'Name',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'userEmail',
          label: 'Email',
          sortable: true,
        },
        { key: 'phoneNumber', label: 'Tel number', sortable: true },
        {
          key: 'actions',
          label: 'Action',
          tdClass: 'text-right',
          thClass: 'text-center',
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25],
      sortBy: 'fullName',
      sortDesc: false,
      sortDirection: 'asc',
      searchText: '',
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
    }
  },
  created() {
    this.getUsers()
  },
  mounted() {
    this.addCityNameColumn()
  },
  methods: {
    addCityNameColumn() {
      if (this.userRole === 'SysAdmin') {
        this.fields.splice(3, 0, {
          key: 'cityName',
          label: 'City name',
          sortable: true,
          sortDirection: 'desc',
        })
      }
    },
    getRequestParams() {
      const params = {}
      params.tenantIds = this.userRole === 'SysAdmin' ? this.selectedTenant.map((tenant) => tenant.tenantID) : []
      params.searchTxt = this.searchText || ''
      params.pageNumber = this.currentPage
      params.pageSize = this.perPage
      return params
    },
    async getUsers() {
      if (this.selectedTenant.length) {
        await this.getUsersApi({ method: 'post', url: ServiceUrls.getUsers, data: this.getRequestParams() })
        if (this.getUsersResponse) {
          this.totalRows = this.getUsersResponse.totalUserCount
        } else {
          this.totalRows = 0
        }
        if (this.getUsersError) {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'USER_LIST_ERROR',
            type: 'danger',
          })
          this.totalRows = 0
        }
      }
    },
    onPageChange(value) {
      this.currentPage = value
      this.getUsers()
    },

    onPageSizeChange(value) {
      this.perPage = value
      this.currentPage = 1
      this.getUsers()
    },
    onSearchUsers: debounce(function () {
      if (this.searchText.length > 2 || this.searchText.length === 0) {
        this.currentPage = 1
        this.getUsers()
      }
    }, 500),

    onUserRowDblclicked(item) {
      if (!this.restrictUsersToMultipleCity) {
        this.$router.push(`/users/userdetails/${item.userObjectID}`)
      }
    },
    selectUser(event, selectedUserInfo) {
      this.$set(selectedUserInfo, 'checked', event)
      if (event) {
        this.usersSelected.push(selectedUserInfo)
      } else {
        const index = this.usersSelected.findIndex((user) => (user.userObjectID = selectedUserInfo.userObjectID))
        if (index > -1) {
          this.usersSelected.splice(index, 1)
        }
      }
    },
    async activateOrDeactivateUser() {
      const status = this.selectedAction !== 0
      const postObj = this.selectedGroup
        ? this.usersSelected.map((user) => {
            const obj = {}
            obj.userObjectID = user.userObjectID
            obj.isActive = status
            return obj
          })
        : [
            {
              userObjectID: this.clickedUser.userObjectID,
              isActive: status,
            },
          ]
      await this.activateDeactivateApi({ method: 'post', url: `${ServiceUrls.activateOrDeactivateUser}`, data: postObj })
      this.activateUserConfirmModal = false
      if (this.activateDeactivateResponse) {
        this.usersSelected = []
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: status ? 'ACTIVATE_USER' : 'DEACTIVATE_USER',
          type: 'success',
        })
        this.getUsers()
      }
      if (this.activateDeactivateError) {
        if (this.activateDeactivateError?.errorMessage) {
          this.$store.commit('common/setCustomToastData', {
            message: this.activateDeactivateError?.errorMessage,
            key: false,
            type: 'danger',
          })
        } else {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger',
          })
        }
      }
    },
    async resetPassword() {
      const payload = this.selectedGroup ? this.usersSelected.map((user) => user.userObjectID) : [this.clickedUser.userObjectID]
      await this.resetPasswordApi({ method: 'post', url: `${ServiceUrls.resetPassword}`, data: payload })
      this.resetPasswordConfirmModal = false
      if (this.resetPasswordResponse) {
        this.usersSelected = []
        this.$store.commit('common/setCustomToastData', {
          message: false,
          key: 'RESET_PASSWORD_SUCCESS',
          type: 'success',
        })
        this.getUsers()
      }
      if (this.resetPasswordError) {
        if (this.resetPasswordError?.errorMessage) {
          this.$store.commit('common/setCustomToastData', {
            message: this.resetPasswordError?.errorMessage,
            key: false,
            type: 'danger',
          })
        } else {
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'RESET_PASSWORD_ERROR',
            type: 'danger',
          })
        }
      }
    },
    userAction(action, isGroup, user) {
      this.selectedAction = action.value
      this.selectedGroup = isGroup
      this.clickedUser = user
      if (action.value === 3) {
        this.$router.push(`/users/userdetails/${user.userObjectID}`)
      } else if (action.value === 0 || action.value === 1) {
        this.activateUserConfirmModal = true
      } else if (action.value === 2) {
        this.resetPasswordConfirmModal = true
      }
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.currentPage = 1
      this.getUsers()
    },
  },
}
</script>
<style lang="scss" scoped></style>
